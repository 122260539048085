var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bgLightGrey"},[_c('div',{staticClass:"",staticStyle:{"max-width":"600px","margin":"auto"}},[_c('div',{staticClass:"spacer m"}),_vm._v(" "),_c('titleBar',{attrs:{"title":_vm.title,"back":"auto","showBackArrowDesktop":true,"subtitle":"","centered":false,"theme":"white","showSignup":false,"containerClass":"donotuse","showDesktop":true,"showAfterScroll":0,"scrollTopOnClick":true,"inline":false,"actionButtonStyle":"small","actionButtonRoundBg":"transparent","iconPack":"fas","actions":[
        {
          icon: 'history',
          to: '/activity',
        },
      ]}}),_vm._v(" "),_c('div',{staticClass:"spacer l"}),_vm._v(" "),_c('br'),_vm._v(" "),(_vm.loading)?_c('div',_vm._l((5),function(i){return _c('article',{key:i,staticClass:"media"},[_c('figure',{staticClass:"media-left"},[_c('p',{staticClass:"image is-64x64"},[_c('b-skeleton',{attrs:{"circle":"","width":"64px","height":"64px"}})],1)]),_vm._v(" "),_c('div',{staticClass:"media-content"},[_c('div',{staticClass:"content"},[_c('p',[_c('b-skeleton',{attrs:{"active":""}}),_vm._v(" "),_c('b-skeleton',{attrs:{"height":"30px"}})],1)]),_vm._v(" "),_vm._m(0,true)])])}),0):_c('div',[(!_vm.notifs.length)?_c('empty',{attrs:{"title":"Nothing to see here — yet","img":"shrug","sub":"From likes to follow and a whole lot more, this is where all the action happens."}}):_c('div',[_c('h2',{staticClass:"title is-3 is-hidden-touch"},[_vm._v(_vm._s(_vm.title))]),_vm._v(" "),_vm._l((_vm.notifs),function(i){return _c('div',{key:i.id},[_c('notifItem',{directives:[{name:"show",rawName:"v-show",value:(_vm.shouldShow(i.type)),expression:"shouldShow(i.type)"}],attrs:{"n":i,"display":"notification","isProfile":_vm.isProfile}})],1)}),_vm._v(" "),_c('div',{staticClass:"empty has-text-centered"})],2)],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"level is-mobile"},[_c('div',{staticClass:"level-left"})])
}]

export { render, staticRenderFns }