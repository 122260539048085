<template>
  <div class="bgLightGrey">
    <div class=" " style="max-width: 600px; margin: auto">
      <div class="spacer m" />
      <!--
        <h1 class="title is-5 is-hidden-mobile">Notifications</h1>
  -->
      <!--   // :img="'this.profile.avatar'"  -->
      <titleBar
        :title="title"
        back="auto"
        :showBackArrowDesktop="true"
        subtitle=""
        :centered="false"
        theme="white"
        :showSignup="false"
        containerClass="donotuse"
        :showDesktop="true"
        :showAfterScroll="0"
        :scrollTopOnClick="true"
        :inline="false"
        actionButtonStyle="small"
        actionButtonRoundBg="transparent"
        iconPack="fas"
        :actions="[
          {
            icon: 'history',
            to: '/activity',
          },
        ]"
      />
      <div class="spacer l" />

      <!--
  
   <button id="enableNotif" class="button is-success right is-small">
   Enable Push Notifications
   </button>
  
  
  
  
   <div class="tabs fat" id="tabsNotif">
  <ul>
  <li class="is-active notifications" data-tab="notifications">
  <a data-tab="notifications">Notifications</a></li>
  <li data-tab="activities" class="activities"><a>Activity</a></li>
  
  </ul>
  </div>


      <article class="message is-info is-hidden-mobile" v-if="!type">
        <div class="message-body">
          <div class="columns is-mobile is-vcentered">
            <div class="column is-narrow">
              <b-icon icon="analytics" icon-pack="fas" pack="fas" size="is-medium"> </b-icon>
            </div>
            <div class="column">
              See which of your posts are getting the most traffic and visibility in your stats dashboard.
            </div>
            <div class="column is-narrow">
              <router-link :to="{ name: 'dashhome' }" style="display: inline-block; margin-left: 5px">
                <b-button
                  size="is-small"
                  type=" is-info is-lightNO"
                  rounded
                  icon-left="analytics"
                  icon-pack="fas"
                  style="line-height: 0.5em"
                >
                  View stats</b-button
                >
              </router-link>
            </div>
          </div>
        </div>
      </article>  -->
      <br />

      <div v-if="loading">
        <article class="media" v-for="i in 5" :key="i">
          <figure class="media-left">
            <p class="image is-64x64">
              <b-skeleton circle width="64px" height="64px"></b-skeleton>
            </p>
          </figure>
          <div class="media-content">
            <div class="content">
              <p>
                <b-skeleton active></b-skeleton>
                <b-skeleton height="30px"></b-skeleton>
              </p>
            </div>
            <nav class="level is-mobile">
              <div class="level-left"></div>
            </nav>
          </div>
        </article>
      </div>
      <div v-else>
        <empty
          v-if="!notifs.length"
          title="Nothing to see here — yet"
          img="shrug"
          sub="From likes to follow and a whole lot more, this is where all the action happens."
        />
        <div v-else>
          <h2 class="title is-3 is-hidden-touch">{{ title }}</h2>
          <div v-for="i in notifs" :key="i.id">
            <notifItem :n="i" display="notification" v-show="shouldShow(i.type)" :isProfile="isProfile" />
          </div>

          <div class="empty has-text-centered"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import layout from "@/components/layout/LayoutModal.vue";

//import titleBar from "@/components/nav/titleBar.vue";
//import coinsTabs from '@/components/nav/coinsTabs.vue';

import notifItem from "@/components/notifItem";

import _ from "lodash";

export default {
  name: "Home",
  components: {
    notifItem,
    // titleBar,
  },
  data() {
    return {
      beLazy: false,
      isProfile: false,
      value: null,
      discussions: [],
      related: [],
      comments: [],
      profile: {},
      notifs: [],
      //  referralSort: 'featured', //default  natural+pin, recent, popular
      filterText: "",
      loading: true,
    };
  },
  metaInfo() {
    return {
      title: "Notifications ", //🔴
      // meta: [{ vmid: 'description', name: 'description', content: this.profile.desc }]
    };
  },
  watch: {
    $route: function (from, to) {
      this.notifs = [];
      this.fetchMain();
    },
  },
  mounted() {
    /* to be executed when mounted */
    var options = {};

    this.fetchMain();
    this.loading = true;
  },
  updated() {
    //fire on route change - all of em!
    //this.loadPost()
  },
  methods: {
    validateUrl(event) {},
    shouldShow(type) {
      return true;
      if (this.dropType == "all") return true;
      return type == this.dropType; // filter
      //return this.checkboxTypesShown.length == 0 || this.checkboxTypesShown.includes(type);
    },
    fetchMain(event) {
      //this.post = {};
      var ctx = this;
      window.API.me.getNotifs(this.opt).then((res) => {
        console.log("ALL items: ", res);
        this.loading = false;
        this.notifs = res;
        var me = this;
        _.delay(function () {
          me.$store.main.dispatch("updateCounts");
        }, 2000);
        if (this.notifs) {
          /* _.delay(function() {
              _.each(me.notifs, function(r) {
                //compute stuff on em?
                //alert(r.desc)
                //r.modifiedSeconds = new Date(r.modified).getTime();
                //if(r.tags && r.tags.length) r.tagKeywords = r.tags.join(' ');
                //r.keywords  = [r.productName ,  r.desc,   r.category, r.tags, r.country, r.tagKeywords].join(' ')
                //r.keywords = r.keywords.toLowerCase();
              });
            }, 200);*/
        }
        //console.log(this.posts[0], 'DEBUG THREAD')
      });
    },
  },

  computed: {
    title: function () {
      if (!this.type) return "Notifications";
      if (this.type == "follow") return "Recent followers";
      return this.type + " notifications";
    },
    loggedUserId: function () {
      return this.$store.main.getters.userId;
    },
    isOwner: function () {
      return this.loggedUserId == this.profile.shortid;
    },
    isDedicatedPage: function () {
      return this.$route.name.startsWith("notificationPage");
    },
    type: function () {
      return this.$route.params.type;
    },
    opt: function () {
      //   var isDedicatedPage = this.$route.name.startsWith('provileActi');
      var limit = 20;
      if (this.isDedicatedPage) limit = 200;
      if (this.globalActivityListing) limit = 200;
      var a = {
        limit: limit, // this.isDedicatedPage ? 200 : 15 // smaller for mini box plz.

        // debug: 1
      };
      if (this.type) a.type = this.type;
      if (this.isOwner && this.showPrivateActivities) a.owner = 1;
      //  if (this.tabLevel == 'admin') a.admin = 1;

      //SWITCH HEWRE!
      return a;
    },
    notifsSorted: function () {
      return this.notifs; //todooo

      var me = this;
      return _.chain(this.referrals)
        .filter(function (r) {
          if (!me.filterText || !r.keywords) return true; //no filter text or not ready...

          return r.keywords.indexOf(me.filterText.toLowerCase()) !== -1; //TODO: make lowercase compare
        })
        .sortBy(function (r) {
          if (me.referralSort == "alpha") {
            return r.productName;
          } else if (me.referralSort == "new") {
            return r.modifiedSeconds * -1;
          } else {
            return r.pinned; //will get first. rest is natural order.
          }

          return 0; //default;
        })
        .value();

      //return this.arrays.sort(compare);
    },
  },
};
</script>
